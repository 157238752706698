<template>
  <c-box
    width="100%"
    height="auto"
    :min-height="['unset', '100vh']"
  >
    <c-box
      margin="auto"
      width="100%"
      overflow="hidden"
    >
      <c-box
        height="100vh"
      >
        <iframe
          :src="getIframeLink"
          frameborder="0"
          style="display:block; width:100%; height: 100%;"
        />
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { ENV } from '@/constants/config'

export default {
  name: 'AboutAhliGizi',
  computed: {
    getIframeLink() {
      return `${ENV.URL_DIETELA_LANDING_PAGE}/ahli-gizi-iframe/`
    },
  },
}
</script>
